import { Controller } from '@hotwired/stimulus';
import { passwordStrength } from 'check-password-strength';

export default class extends Controller<HTMLElement> {
  static targets = ['display', 'bar', 'text', 'input'];
  declare readonly displayTarget: HTMLElement;
  declare readonly barTargets: HTMLElement[];
  declare readonly textTarget: HTMLElement;
  declare readonly inputTarget: HTMLInputElement;

  password = '';
  colors = [
    { bg: 'bg-red-600', text: 'text-red-600' },
    { bg: 'bg-yellow-400', text: 'text-yellow-400' },
    { bg: 'bg-green-600', text: 'text-green-600' },
    { bg: 'bg-green-600', text: 'text-green-600' },
  ];

  defaultColor = { bg: 'bg-gray-500', text: 'text-gray-500' };

  update() {
    this.password = this.inputTarget.value;

    this.resetBars();
    if (this.password.length) {
      const strength = passwordStrength(this.password);
      const color = this.colors[strength.id];
      this.setBars(strength.id, color.bg);
      this.setText(strength.value, color.text);
    } else {
      this.setBarsColor(this.defaultColor.bg);
      this.setText('Too short', this.defaultColor.text);
    }
  }

  focus() {
    this.displayTarget.hidden = false;
    this.update();
  }

  setText(text: string, color: string) {
    this.textTarget.innerHTML = text;
    this.textTarget.classList.remove(...this.textTarget.classList);
    this.textTarget.classList.add(color);
  }

  setBarsColor(color: string | null) {
    this.barTargets.forEach((bar) => {
      if (color) {
        bar.classList.add(color);
      }
    });
  }

  resetBars() {
    this.barTargets.forEach((bar) => {
      bar.classList.remove(...bar.classList);
    });
  }

  setBars(id: number, color: string) {
    this.barTargets.forEach((bar, i) => {
      bar.classList.add(i <= id ? color : this.defaultColor.bg);
    });
  }
}
